import { Injectable } from '@angular/core'

import { Httpclient } from './Httpclient.service'
import { Product, CategoryProduct, ProductItem, Price, Suggestion } from 'app/models'
import { Status } from 'app/models/Status';
import { ProductPrice } from 'app/models/product/ProductPrice';
import {AppConfigService} from "./AppConfigService.service";
import { ProductVat } from 'app/models/product/ProductVat';

@Injectable()
export class ProductService {

    public endpoint: string

    constructor (
        private _Httpclient: Httpclient,
        private config:AppConfigService
    ) {
        this.endpoint = config.endpoints["product"].get()
    }

    public async getLast (): Promise<Array<Product>> {
        try {
            return Promise.resolve([
                // new Product(1, "SET", "Menu enfant", 10.00, 10),
                // new Product(2, "SET", "Menu tacos", 7, 10),
                // new Product(3, "EXTEND", "Galette de pommes de terre", 1.00, 10),
                // new Product(4, "SIMPLE", "Tacos Simple", 6.00, 10),
                // new Product(5, "EXTEND", "Nuggets", 0.00, 10),
            ])
        } catch (error) {
            return Promise.reject(error)
        }
    }

    public async getType (type: string): Promise<Array<Product>> {
        try {
            let res = await this._Httpclient.get(`${this.endpoint}/type/${type}`)
            let products = res
            .filter((product) => product.active === 'TRUE')
            .map(product => new Product(
                parseInt(product.product_id), 
                type, 
                product.name, 
                product.code,
                product.color,
                product.kind,
                product.picture,
                parseInt(product.order),
                (product.printer_bold === "TRUE") ? true : false,
                new ProductPrice(product.product_price.ratecard_product_id, product.product_price.price_wvat),
                null,
                null,
                (type === "SET") ? parseInt(product.product_set_id) : null,
                (type === "EXTEND") ? parseInt(product.product_extend_id) : null,
                product.designation,
                product.description,
                product.printer_placement,
                null,
                null,
                null,
                (product.available === "TRUE") ? true : false,
                (product.favourite === "TRUE") ? true : false
            ))
            .sort(this.compare)
            return Promise.resolve(products)
        } catch (error) {
            return Promise.reject(error)
        }
    }

    public async getReferencesFromCategoryIdAndType(id: number, type: string): Promise<Array<Product>> {
        try {
            let res = await this._Httpclient.get(`${this.endpoint}/reference/category/${id}/kind/${type}`)
            let products = res.map(product => new Product(
                parseInt(product.product_id), 
                type, 
                product.name, 
                product.code,
                product.color,
                product.kind,
                product.picture,
                product.order ? parseInt(product.order) : undefined,
                (product.printer_bold === "TRUE") ? true : false,
                new ProductPrice(product.product_price.ratecard_product_id, product.product_price.price_novat),
                null,
                null,
                (type === "SET") ? parseInt(product.product_set_id) : null,
                (type === "EXTEND") ? parseInt(product.product_extend_id) : null,
                product.designation,
                product.description,
                product.printer_placement,
                null,
                null,
                null,
                (product.available === "TRUE") ? true : false,
                (product.favourite === "TRUE") ? true : false
            )).sort((a, b) => { return (a.order === b.order) ? 0 : (a.order < b.order) ? -1 : 1 })
            return products
        } catch (error) {
            return Promise.reject(error)
        }
    }

    public async find(productId: number): Promise<Product> {
        try {
            let res = await this._Httpclient.get(`${this.endpoint}/${productId}`)
            let product = new Product(
                parseInt(res.product_id), 
                res.type, 
                res.name, 
                res.code,
                res.color,
                res.kind,
                res.picture,
                parseInt(res.order),
                (res.printer_bold === "TRUE"),
                new ProductPrice(parseInt(res.product_price.ratecard_product_id), res.product_price.price_wvat),
                (res.product_extend_category) ? res.product_extend_category
                .filter((category) => category.active === 'TRUE')
                .map(category => new CategoryProduct(
                    category.name,
                    category.code,
                    category.icon_code,
                    category.min,
                    category.max,

                    (category.product) ? category.product
                    .filter((product) => product.active === 'TRUE')
                    .map(product => new Product(
                        parseInt(product.product_id), 
                        product.type, 
                        product.name, 
                        product.code, 
                        product.color,
                        product.kind,
                        product.picture,
                        parseInt(product.order),
                        (product.printer_bold === "TRUE"),
                        new ProductPrice(product.product_price.ratecard_product_id, product.product_price.price_wvat),
                        null,
                        null,
                        null,
                        (res.type !== "SET") ? product.product_extend_id : null,
                        null,
                        null,
                        null,
                        (res.type !== "SET") ? parseInt(product.product_extend_assoc_id) : null,
                        (res.type === "SET") ? parseInt(product.product_set_assoc_id) : null
                    )).sort((a, b) => { return (a.order === b.order) ? 0 : (a.order < b.order) ? -1 : 1 }) : [],                    
                    (res.type === "SET") ? parseInt(category.category_set_id) : parseInt(category.category_extend_id),
                    (res.type === "SET") ? "SET" : "EXTEND",
                    parseInt(category.order),
                    category.designation,
                    category.description
                )).sort((a, b) => { return (a.order === b.order) ? 0 : (a.order < b.order) ? -1 : 1 }) : [],
                (res.product_item) ? res.product_item.map(item => new ProductItem(
                    parseInt(res.product_id),
                    item.name,
                    item.code,
                    (item.quantity) ? parseInt(item.quantity) : 1,
                    parseInt(item.stock_id),
                    item.product_item_id,
                    null,
                    (item.min) ? parseInt(item.min) : 0,
                    parseInt(item.max),
                    item.order ? parseInt(item.order) : 0,
                    item.picture,
                    (item.printer_bold === "TRUE") ? true : false,
                    parseFloat(item.weight)
                    // item.description,
                )).sort((a, b) => { return (a.order === b.order) ? 0 : (a.order < b.order) ? -1 : 1 }) : [],
                (res.type === "SET") ? res.product_set_id : null,
                (res.type === "EXTEND") ? res.product_extend_id : null,
                res.designation,
                res.description,
                res.printer_placement,
                null,
                null,
                res.prices.map(p => new Price(parseInt(p.ratecard_product_id), parseFloat(p.price_wvat), p.days, p.start_at, p.end_at)),
                (res.available === "TRUE") ? true : false,
                (res.favourite === "TRUE") ? true : false,
                res.family_id,
                res.plu,
                res.taxRates
            )
            return Promise.resolve(product)
        } catch (error) {
            return Promise.reject(error)
        }
    }

    public async findTypeCount (type: string): Promise<number> {
        try {
            let products = await this.getType(type)
            let count = products.length
            return Promise.resolve(count)
        } catch (error) {
            return Promise.reject(error)
        }
    }

    public async add (product: Product): Promise<Status> {
        try {
            let body = {
                ...product,
                printer_bold: (product.printer_bold) ? "TRUE" : "FALSE",
                available: (product.available) ? "TRUE" : "FALSE"
            }
            let res = await this._Httpclient.post(`${this.endpoint}`, body)
            if (res.product_id) res.lastInsertId = res.product_id
            return Promise.resolve(new Status(res))
        } catch (error) {
            return Promise.reject(error)
        }
    }

    public async copy (id: number): Promise<Status> {
        try {
            let res = await this._Httpclient.post(`${this.endpoint}/${id}/copy`)
            return Promise.resolve(new Status(res))
        } catch (error) {
            return Promise.reject(error)
        }
    }

    public async edit (id: number, product: Product): Promise<Status> {
        try {
            let body = {
                ...product,
                printer_bold: (product.printer_bold) ? "TRUE" : "FALSE",
                available: (product.available) ? "TRUE" : "FALSE",
                favourite: (product.favourite) ? "TRUE" : "FALSE"
            }
            let res = await this._Httpclient.put(`${this.endpoint}/${id}`, body)
            return Promise.resolve(new Status(res))
        } catch (error) {
            return Promise.reject(error)
        }
    }

    public async editItems (id: number, items: Array<ProductItem>): Promise<Status> {
        try {
            let res = await this._Httpclient.put(`${this.endpoint}/${id}/items`, items)
            return Promise.resolve(new Status(res))
        } catch (error) {
            return Promise.reject(error)
        }
    }

    public async editAssociations (id: number, type: string, associations: Array<CategoryProduct>): Promise<Status> {
        try {
            let res = await this._Httpclient.put(`${this.endpoint}/${id}/associations/${type}`, associations)
            return Promise.resolve(new Status(res))
        } catch (error) {
            return Promise.reject(error)
        }
    }

    public async editPrices (id: number, prices: Array<Price>): Promise<Status> {
        try {
            let res = await this._Httpclient.put(`${this.endpoint}/${id}/prices`, prices)
            return Promise.resolve(new Status(res))
        } catch (error) {
            return Promise.reject(error)
        }
    }

    public async editVats (id: number, vats: ProductVat): Promise<Status> {
        try {
            const body = Object.keys(vats).map(code => {
                return {
                    code: code.toUpperCase(),
                    taxRate: vats[code]
                }
            })
            let res = await this._Httpclient.put(`${this.endpoint}/${id}/vats`, body)
            return Promise.resolve(new Status(res))
        } catch (error) {
            return Promise.reject(error)
        }
    }

    public async editSuggestions (id: number, suggestions: Array<Suggestion>): Promise<Status> {
        try {
            let res = await this._Httpclient.put(`${this.endpoint}/${id}/suggestions`, suggestions)
            return Promise.resolve(new Status(res))
        } catch (error) {
            return Promise.reject(error)
        }
    }

    public async delete (id: number): Promise<Status> {
        try {
            let res = await this._Httpclient.delete(`${this.endpoint}/${id}`)
            return Promise.resolve(new Status(res))
        } catch (error) {
            return Promise.reject(error)
        }
    }

    private compare( a, b ) {
        if ( a.name < b.name ){
        return -1;
        }
        if ( a.name > b.name ){
        return 1;
        }
        return 0;
    }
}