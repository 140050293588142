import { Component, Input, OnInit, ViewChild, AfterViewInit } from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import { DefaultEditor } from 'ng2-smart-table'


@Component({
  template: `
  <i class="material-icons" style="color: #CCC">search</i>
  <div class="ripple-container"></div>
  `,
})
export class SuggestionEditorComponent extends DefaultEditor {
  

  constructor(
    private _Dialog: MatDialog
  ) {
    super();
  }

}


