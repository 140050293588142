import { Injectable } from '@angular/core'

import { Httpclient } from './Httpclient.service';
import { Status } from 'app/models/Status';
import {AppConfigService} from "./AppConfigService.service";
import { Suggestion, SuggestionDelivery, SuggestionDisplay } from 'app/models/product/Suggestion';
import { Product } from 'app/models';

@Injectable()
export class SuggestionService {

    public endpoint: string

    constructor (
        private _Httpclient: Httpclient,
        private config:AppConfigService
    ) {
        this.endpoint = config.endpoints["suggestions"].get()
    }

    public async get (): Promise<Array<Suggestion>> {
        try {
            let res = await this._Httpclient.get(`${this.endpoint}`)
            let suggestions = res.map(suggestion => new Suggestion(
                suggestion.id,
                suggestion.name,
                suggestion.code,
                suggestion.designation,
                suggestion.secondary,
                suggestion.description,
                suggestion.available,
                suggestion.kind,
            ))
            return Promise.resolve(suggestions)
        } catch (error) {
            return Promise.reject(error)
        }
    }

    public async getByParentId (parentId: number): Promise<Array<Suggestion>> {
        try {
            let res = await this._Httpclient.get(`${this.endpoint}/parents/${parentId}`)
            let suggestions = res.map(suggestion => new Suggestion(
                suggestion.id,
                suggestion.name,
                suggestion.code,
                suggestion.designation,
                suggestion.secondary,
                suggestion.description,
                suggestion.available,
                suggestion.kind,
            ))
            return Promise.resolve(suggestions)
        } catch (error) {
            return Promise.reject(error)
        }
    }

    public async find (id: number): Promise<Suggestion> {
        try {
            let res = await this._Httpclient.get(`${this.endpoint}/${id}`)
            let suggestion = new Suggestion(
                res.id,
                res.name,
                res.code,
                res.designation,
                res.secondary,
                res.description,
                res.available,
                res.kind,
                (res.mapping) ? res.mapping.ubereatsId : undefined,
                res.products,
                res.parents,
                res.deliveryModes,
                res.displayMethods,
            )
            return Promise.resolve(suggestion)
        } catch (error) {
            return Promise.reject(error)
        }
    }

    public async add (suggestion: Suggestion): Promise<Status> {
        try {
            let body = {
                ...suggestion
            }
            let res = await this._Httpclient.post(`${this.endpoint}`, body)
            if (res.suggestion_id) res.lastInsertId = res.suggestion_id
            return Promise.resolve(res)
        } catch (error) {
            return Promise.reject(error)
        }
    }

    public async edit (id: number, suggestion: Suggestion): Promise<Status> {
        try {
            let body = {
                ...suggestion
            }
            let res = await this._Httpclient.put(`${this.endpoint}/${id}`, body)
            return Promise.resolve(res)
        } catch (error) {
            return Promise.reject(error)
        }
    }

    public async editDisplayMethods (id: number, methods: Array<SuggestionDisplay>): Promise<Status> {
        try {
            let res = await this._Httpclient.put(`${this.endpoint}/${id}/displayMethods`, methods)
            return Promise.resolve(res)
        } catch (error) {
            return Promise.reject(error)
        }
    }

    public async editDeliveryModes (id: number, deliveries: Array<SuggestionDelivery>): Promise<Status> {
        try {
            let res = await this._Httpclient.put(`${this.endpoint}/${id}/deliveryModes`, deliveries)
            return Promise.resolve(res)
        } catch (error) {
            return Promise.reject(error)
        }
    }

    public async editProducts (id: number, inputs: Array<{id: number, order: number}>): Promise<Status> {
        try {
            let res = await this._Httpclient.put(`${this.endpoint}/${id}/products`, inputs)
            return Promise.resolve(res)
        } catch (error) {
            return Promise.reject(error)
        }
    }

    public async delete (id: number): Promise<Status> {
        try {
            let res = await this._Httpclient.delete(`${this.endpoint}/${id}`)
            return Promise.resolve(res)
        } catch (error) {
            return Promise.reject(error)
        }
    }
}