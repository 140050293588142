import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import {APP_INITIALIZER, NgModule} from '@angular/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { HttpModule } from '@angular/http'
import { RouterModule } from '@angular/router'
import { NgSelectModule } from '@ng-select/ng-select'
import { CommonModule } from '@angular/common'
import { ToastrModule } from 'ngx-toastr'
import { Ng2SmartTableModule } from 'ng2-smart-table'
import { NgxUiLoaderModule, NgxUiLoaderConfig, NgxUiLoaderRouterModule } from 'ngx-ui-loader'

import { AppRoutingModule } from './app.routing'
import { ComponentsModule } from './layouts/components/components.module'

import { AppComponent } from './app.component'
import { ComponentsModule as PersonalComponentsModule } from './components/components.module'

import {
  AgmCoreModule
} from '@agm/core'

import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component'
import { AuthLayoutComponent } from './layouts/auth-layout/auth-layout.component'
import { Httpclient } from './services/Httpclient.service'
import { LoginService } from './services/Login.service'
import {
  EstablishmentService,
  ProductService,
  StockService,
  CategoryService,
  StaffService,
  ProductionLineService,
  ProductionPlacementService,
  PrinterService,
  OptionService,
  FamilyService,
  OpeningHourService,
  ProviderService,
  CustomerService,
  CheckoutService,
  InventoryService,
  KpiService, 
  SuggestionService, 
  AppConfigService
} from './services'

import { 
  DeliverectService 
} from './services/Deliverect.service'
import {BrowserModule} from "@angular/platform-browser";
import { OrderByPipe } from './components/pipes/order'

const loaderConfig: NgxUiLoaderConfig = {
  "bgsColor": "#00ACC1",
  "bgsOpacity": 0.5,
  "bgsPosition": "bottom-right",
  "bgsSize": 60,
  "bgsType": "ball-spin-clockwise",
  "blur": 5,
  "fgsColor": "#D3D281",
  "fgsPosition": "center-center",
  "fgsSize": 60,
  "fgsType": "three-strings",
  "gap": 24,
  "logoPosition": "center-center",
  "logoSize": 120,
  "logoUrl": "",
  "masterLoaderId": "master",
  "overlayBorderRadius": "0",
  "overlayColor": "rgba(200, 200, 200, 0.6)",
  "pbColor": "#00ACC1",
  "pbDirection": "ltr",
  "pbThickness": 3,
  "hasProgressBar": true,
  "text": "",
  "textColor": "#FFFFFF",
  "textPosition": "center-center",
  "threshold": 500
}

export function appInit(appConfigService: AppConfigService) {
  return () => appConfigService.load();
}

@NgModule({
  imports: [
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule.withConfig({warnOnNgModelWithFormControl: 'never'}),
    HttpModule,
    ComponentsModule,
    RouterModule,
    AppRoutingModule,
    NgSelectModule,
    CommonModule,
    Ng2SmartTableModule,
    NgxUiLoaderModule.forRoot(loaderConfig),
    NgxUiLoaderRouterModule,
    ToastrModule.forRoot(),
    AgmCoreModule.forRoot({
      apiKey: 'YOUR_GOOGLE_MAPS_API_KEY'
    }),
    PersonalComponentsModule
  ],
  declarations: [
    AppComponent,
    AdminLayoutComponent,
    AuthLayoutComponent
  ],
  providers: [
    AppConfigService,
    {
      provide: APP_INITIALIZER,
      useFactory: appInit,
      multi: true,
      deps: [AppConfigService]
    },
    Httpclient,
    LoginService,
    EstablishmentService,
    ProductService,
    FamilyService,
    StockService,
    CategoryService,
    StaffService,
    PrinterService,
    ProductionLineService,
    ProductionPlacementService,
    OptionService,
    OpeningHourService,
    ProviderService,
    CustomerService,
    CheckoutService,
    InventoryService,
    KpiService,
    SuggestionService,
    DeliverectService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
