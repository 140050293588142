import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core'
import { AbstractControl, FormGroup, FormBuilder } from '@angular/forms';
import { SuggestionDisplay } from 'app/models/product/Suggestion';

@Component({
    selector: 'card-display-methods',
    templateUrl: './card.displayMethods.component.html',
    styleUrls: ['./card.displayMethods.component.css']
})
export class CardDisplayMethodsComponent implements OnInit {
    
    @Input() displayMethods: Array<SuggestionDisplay>
    
    @Output() onRemove: EventEmitter<number> = new EventEmitter
    @Output() onChange: EventEmitter<Array<SuggestionDisplay>> = new EventEmitter
    
    public modes = { 
        onlyOnce: false, 
        beforePayment: false, 
        productInMenu: false,
        noneSelected: false
    } 

    public form: FormGroup
    public onlyOnce: AbstractControl
    public beforePayment: AbstractControl
    public productInMenu: AbstractControl
    public noneSelected: AbstractControl

    public readonly: boolean = false
    public create: boolean = false

    constructor(
        private _FormBuilder: FormBuilder,
    ) { }

    public ngOnInit(): void {
        // Initialisation detail form
        this.form = this._FormBuilder.group({
            'onlyOnce': [true],
            'beforePayment': [true],
            'productInMenu': [true],
            'noneSelected': [true]
        })
        this.onlyOnce = this.form.controls['onlyOnce']
        this.beforePayment = this.form.controls['beforePayment']
        this.productInMenu = this.form.controls['productInMenu']
        this.noneSelected = this.form.controls['noneSelected']
        
        this.displayMethods.forEach(dm => {
            if (dm === SuggestionDisplay.ONLY_ONCE) this.modes.onlyOnce = true
            if (dm === SuggestionDisplay.BEFORE_PAYMENT) this.modes.beforePayment = true
            if (dm === SuggestionDisplay.PRODUCT_IN_MENU) this.modes.productInMenu = true
            if (dm === SuggestionDisplay.NONE_SELECTED) this.modes.noneSelected = true
        })
    }
    public handleChange(): void {
        const response = []
        if (this.modes.onlyOnce) response.push(SuggestionDisplay.ONLY_ONCE)
        if (this.modes.beforePayment) response.push(SuggestionDisplay.BEFORE_PAYMENT)
        if (this.modes.productInMenu) response.push(SuggestionDisplay.PRODUCT_IN_MENU)
        if (this.modes.noneSelected) response.push(SuggestionDisplay.NONE_SELECTED)
        this.displayMethods = response
        this.onChange.emit(this.displayMethods)
    }
}