import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core'
import { Suggestion } from 'app/models'
import { SuggestionService } from 'app/services';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ConfirmDialog } from 'app/components/dialog/confirm.dialog';
import { MatDialog } from '@angular/material';
import { ToastrService } from 'ngx-toastr';
import { LocalDataSource } from 'ng2-smart-table';
import { CopyRenderComponent } from 'app/components/cell/render/copy.render';
import { CopyEditorComponent } from 'app/components/cell/editor/copy.editor.component';
import { SuggestionRenderComponent } from 'app/components/cell/render/suggestion.render.component';
import { SuggestionEditorComponent } from 'app/components/cell/editor/suggestion.editor.component';
import { ToggleRenderComponent } from 'app/components/cell/render/toggle.render.component';
import { ToggleEditorComponent } from 'app/components/cell/editor/toggle.editor.component';

@Component({
    selector: 'table-suggestions',
    template: `<ng2-smart-table 
                [settings]="settings"
                [source]="source"
                (createConfirm)="handleAdd($event)"
                (editConfirm)="handleEdit($event)"
                (deleteConfirm)="handleDelete($event)"
            ></ng2-smart-table>`,
    styleUrls: ['./table.suggestions.component.css']
})
export class TableSuggestionsComponent implements OnInit {

    public source: LocalDataSource
    public settings = {
        hideHeader: true,
        add: {
            confirmCreate: true,
            addButtonContent: `
            <a mat-button class="nav-link active product-add">
                <i class="material-icons">add_circle_outline</i>
                <div class="ripple-container"></div>
            </a>
            `,
            createButtonContent: `
            <i class="material-icons">check_circle_outline</i>
            <div class="ripple-container"></div>
            `,
            cancelButtonContent: `
            <i class="material-icons">highlight_off</i>
            <div class="ripple-container"></div>
            `
        },
        edit: {
            confirmSave: true,
            editButtonContent: `
            <i class="material-icons">play_circle_outline</i>
            <div class="ripple-container"></div>
            `,
            saveButtonContent: `
            <i class="material-icons">check_circle_outline</i>
            <div class="ripple-container"></div>
            `,
            cancelButtonContent: `
            <i class="material-icons">highlight_off</i>
            <div class="ripple-container"></div>
            `
        },
        delete: {
            confirmDelete: true,
            deleteButtonContent: `
            <i class="material-icons">remove_circle_outline</i>
            <div class="ripple-container"></div>
            `
        },
        columns: {
            id: {
                title: 'Voir',
                filter: false,
                editable: false,
                type: 'custom',
                renderComponent: SuggestionRenderComponent,
                editor : {
                    type: 'custom',
                    component: SuggestionEditorComponent
                }
            },
            code: {
                title: 'Code',
                type: 'text',
                sort: true,
                sortDirection: 'asc'
            },
            name: {
                title: 'Nom',
                type: 'text',
                sort: true,
                sortDirection: 'asc'
            },
            designation: {
                title: 'Nom Long',
                type: 'text',
                sort: true,
                sortDirection: 'asc'
            },
            description: {
                title: 'Description',
                type: 'text',
                sort: true,
                sortDirection: 'asc'
            },
            available: {
                title: 'Disponible',
                type: 'custom',
                filter: true,
                renderComponent: ToggleRenderComponent,
                editor: {
                    type: 'custom',
                    component: ToggleEditorComponent
                }
            },
            // copy: {
            //     title: 'Copier',
            //     filter: false,
            //     type: 'custom',
            //     renderComponent: CopyRenderComponent,
            //     onComponentInitFunction: (instance) => {
            //         instance.copiedData.subscribe((suggestionId) => {
            //             this.handleCopy(suggestionId)
            //         })
            //     },
            //     editor: {
            //         type: 'custom',
            //         component: CopyEditorComponent
            //     }
            // },
        }
    }
    
    @Input()
    public suggestions: Array<Suggestion>
    @Input()
    public kind: string

    @Output()
    public onCreate: EventEmitter<any> = new EventEmitter
    @Output()
    public onEdit: EventEmitter<any> = new EventEmitter
    @Output()
    public onDelete: EventEmitter<any> = new EventEmitter

    constructor(
        private _SuggestionService: SuggestionService,
        private _LoaderService: NgxUiLoaderService,
        private _Dialog: MatDialog,
        private _ToasterService: ToastrService
    ) { }

    public ngOnInit(): void {
        this.source = new LocalDataSource(this.suggestions.map(suggestion => {
            return {
                id: suggestion.id,
                name: suggestion.name || '',
                code: suggestion.code || '',
                designation: suggestion.designation,
                description: suggestion.description,
                available: suggestion.available,
                copy: suggestion.id,
            }
        }))

    }

    public async handleAdd($event): Promise<void> {
        try {
            this._LoaderService.start()
            let suggestion = Object.assign(new Suggestion(), {
                code: $event.newData.code,
                name: $event.newData.name,
                designation: $event.newData.designation,
                description: $event.newData.description,
                kind: this.kind,
                available: $event.newData.available
            })
            const res = await this._SuggestionService.add(suggestion)
            $event.newData.id = res.lastInsertId
            $event.newData.copy = res.lastInsertId
            await $event.confirm.resolve({
                ...$event.newData,
                kind: this.kind
            })
            this.onCreate.emit($event.newData)
            this._LoaderService.stop()
            this._ToasterService.success("Ajout de la suggestion effectué.")
            return Promise.resolve()
        } catch (error) {
            console.error("TableSuggestionComponent::handleAdd", error)
            this._LoaderService.stop()
            if (error.json().code === "0x1007") this._ToasterService.warning(`Vous n'êtes pas autorisé à effectuer cette action`)
            else this._ToasterService.error("Erreur lors de l'ajout de la suggestion.")
            return Promise.reject(error)
        }
    }
    
    // public async handleCopy(suggestionId: number): Promise<void> {
    //     try {
    //       this._LoaderService.start()
    //       const result = await this._SuggestionService.copy(suggestionId, this.kind)
    //       console.log(result)
    //       const copiedSuggestion = await this._SuggestionService.find(result.lastInsertId)
    //       await this.source.add({
    //         ...copiedSuggestion,
    //         copy: copiedSuggestion.id
    //       })
    //       await this.source.refresh()
    //       this._LoaderService.stop()
    //       this._ToasterService.success("Copie de la suggestion effectué.")
    //       return Promise.resolve()
    //     } catch (error) {
    //       this._LoaderService.stop()
    //       if (error.json().code === "0x1007") this._ToasterService.warning(`Vous n'êtes pas autorisé à effectuer cette action`)
    //       else this._ToasterService.error("Erreur lors de la copie de la suggestion.")
    //       console.error("CopyRenderComponent::handleCopy", error, this)
    //       return Promise.reject(error)
    //     }
    // }
    
    public async handleEdit($event): Promise<void> {
        try {
            this._LoaderService.start()
            let suggestion = Object.assign(new Suggestion(), {
                code: $event.newData.code,
                name: $event.newData.name,
                designation: $event.newData.designation,
                description: $event.newData.description,
                available: $event.newData.available,
                kind: this.kind
            })
            await this._SuggestionService.edit($event.data.id, suggestion)
            await $event.confirm.resolve()
            this.onEdit.emit($event.newData)
            this._LoaderService.stop()
            this._ToasterService.success("Modification de la suggestion effectué.")
            return Promise.resolve()
        } catch (error) {
            console.error("TableSuggestionComponent::handleEdit", error)
            this._LoaderService.stop()
            if (error.json().code === "0x1007") this._ToasterService.warning(`Vous n'êtes pas autorisé à effectuer cette action`)
            else this._ToasterService.error("Erreur lors de la modification de la suggestion.")
            return Promise.reject(error)
        }
    }
    
    public async handleDelete($event): Promise<void> {
        try {
            let dialog = this._Dialog.open(ConfirmDialog, {width: '400px'})
            let result = await dialog.afterClosed().toPromise()
            if (!result) return
            this._LoaderService.start()
            await this._SuggestionService.delete($event.data.id)
            await $event.confirm.resolve()
            this.onDelete.emit($event.index)
            this._LoaderService.stop()
            this._ToasterService.success("Suppression de la suggestion effectué.")
            return Promise.resolve()
        } catch (error) {
            console.error("TableSuggestionComponent::handleDelete", error)
            this._LoaderService.stop()
            if (error.json().code === "0x1007") this._ToasterService.warning(`Vous n'êtes pas autorisé à effectuer cette action`)
            else this._ToasterService.error("Erreur lors de la suppression du suggestion.")
            return Promise.reject(error)
        }
    }
}