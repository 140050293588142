import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core'
import { AbstractControl, FormGroup, FormBuilder } from '@angular/forms';
import { SuggestionDelivery } from 'app/models/product/Suggestion';

@Component({
    selector: 'card-delivery-modes',
    templateUrl: './card.deliveryModes.component.html',
    styleUrls: ['./card.deliveryModes.component.css']
})
export class CardDeliveryModesComponent implements OnInit {
    
    @Input() deliveryModes: Array<SuggestionDelivery>
    
    @Output() onRemove: EventEmitter<number> = new EventEmitter
    @Output() onChange: EventEmitter<Array<SuggestionDelivery>> = new EventEmitter
    
    public modes = { eatIn: false, takeAway: false } 

    public form: FormGroup
    public eatIn: AbstractControl
    public takeAway: AbstractControl

    public readonly: boolean = false
    public create: boolean = false

    constructor(
        private _FormBuilder: FormBuilder,
    ) { }

    public ngOnInit(): void {
        // Initialisation detail form
        this.form = this._FormBuilder.group({
            'eatIn': [true],
            'takeAway': [true]
        })
        this.eatIn = this.form.controls['eatIn']
        this.takeAway = this.form.controls['takeAway']
        
        this.deliveryModes.forEach(dm => {
            if (dm === SuggestionDelivery.EAT_IN) this.modes.eatIn = true
            if (dm === SuggestionDelivery.TAKE_AWAY) this.modes.takeAway = true
        })
    }
    public handleChange(): void {
        const response = []
        if (this.modes.eatIn) response.push(SuggestionDelivery.EAT_IN)
        if (this.modes.takeAway) response.push(SuggestionDelivery.TAKE_AWAY)
        this.deliveryModes = response
        this.onChange.emit(this.deliveryModes)
    }
}