import { Component, Input, Output, EventEmitter } from '@angular/core'
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms'
import { MatDialog } from '@angular/material'
import { ConfirmDialog } from 'app/components/dialog/confirm.dialog'
import { Suggestion } from 'app/models'

@Component({
    selector: 'card-suggestion',
    templateUrl: './card.suggestions.component.html',
    styleUrls: ['./card.suggestions.component.css']
})
export class CardSuggestionComponent {
    
    @Input() suggestion: Suggestion
    @Input() suggestions: Array<Suggestion>
    @Input() index: number
    @Input() readonly: boolean
    
    @Output() onEdit: EventEmitter<number> = new EventEmitter
    @Output() onRemove: EventEmitter<number> = new EventEmitter
    @Output() onChange: EventEmitter<Array<number|Suggestion|boolean>> = new EventEmitter

    public create: boolean = false
    public form: FormGroup
    public code: AbstractControl
    public name: AbstractControl
    public designation: AbstractControl
    public description: AbstractControl

    constructor(
        private _FormBuilder: FormBuilder,
        private _Dialog: MatDialog
    ) {
        // Initialisation detail form
        this.form = this._FormBuilder.group({
            'code': [''],
            'name': ['', [Validators.required]],
            'designation': [''],
            'description': ['']
        })
        this.code = this.form.controls['code']
        this.name = this.form.controls['name']
        this.designation = this.form.controls['designation']
        this.description = this.form.controls['description']
    }

    public ngOnInit (): void { 
        this.suggestions.sort(this.compare)
    }
    public handleEdit(): void {
        this.onEdit.emit(this.index)
    }
    public handleVoid(): void {
        return
    }
    
    public async handleRemove(): Promise<void> {
        try {
            let dialog = this._Dialog.open(ConfirmDialog, {width: '400px'})
            let result = await dialog.afterClosed().toPromise()
            if (!result) return
            this.onRemove.emit(this.index)
        } catch (error) {
            console.error("CardSuggestionComponent::handleRemove", error)
        }
    }
    public handleSelect(): void {
        if (!this.suggestion.id) {
            this.suggestion = new Suggestion()
            this.create = true
        } else {
            this.create = false
            this.onChange.emit([this.index, this.suggestions.filter(s => s.id === this.suggestion.id)[0]])
            this.suggestion = Object.assign(new Suggestion(), this.suggestions.filter(s => s.id === this.suggestion.id)[0])
        }
    }

    private compare( a, b ) {
        if ( a.code < b.code || a.name < b.name){
        return -1;
        }
        if ( a.code < b.code || a.name > b.name ){
        return 1;
        }
        return 0;
    }
}