import { Component, OnInit } from '@angular/core';
import { EstablishmentService } from 'app/services';
import { ToastrService } from 'ngx-toastr';
import { AppService } from 'app/app.service';
import { Establishment } from 'app/models';
import { LoginService } from 'app/services/Login.service';
import { Router } from '@angular/router';

declare const $: any;
declare interface RouteInfo {
    path: string;
    title: string;
    icon: string;
    class: string;
}
export const ROUTES: Array<{category:string, routes:Array<RouteInfo>}> = [
    { category: 'login', routes : [{path: '/login/select', title: 'Mes établissements',  icon: 'home', class: '' }] },,
    { category: 'restaurant', routes: [
        { path: '/establishment/home', title: 'Statistiques',  icon: 'trending_up', class: '' },
        { path: '/establishment/info', title: 'Informations',  icon: 'info', class: '' },
        // { path: '/establishment/options/1', title: 'Options',  icon:'settings', class: '' },
        { path: '/establishment/hours', title: 'Horaires',  icon: 'access_time', class: '' },
        { path: '/establishment/staff', title: 'Equipes',  icon: 'people', class: '' },
        { path: '/establishment/customer', title: 'Clients',  icon: 'contact_phone', class: '' },
    ] },
    { category: 'services', routes : [
        { path: '/sales/checkout', title: 'Caisses',  icon: 'apps', class: '' },
        { path: '/sales/category/product', title: 'Catégories',  icon:'dataset', class: '' },
        { path: '/sales/family', title: 'Familles',  icon:'library_books', class: '' },
        // { path: '/sales/picking', title: 'Cuisine',  icon:'whatshot', class: '' },
        { path: '/sales/product', title: 'Produits',  icon:'shopping_basket', class: '' },
        { path: '/sales/suggestions', title: 'Suggestions',  icon:'playlist_add', class: '' },
        { path: '/sales/comments', title: 'Commentaires',  icon:'comment', class: '' },
        { path: '/sales/productions', title: 'Cuisines',  icon:'restaurant_menu', class: '' },
        { path: '/sales/printer', title: 'Imprimantes',  icon:'print', class: '' },
    ] },
    { category: 'matières premières', routes: [
        { path: '/store/inventory', title: 'Inventaires', icon: 'assignment_turned_in', class: '' },
        { path: '/store/provider', title: 'Fournisseurs',  icon:'store', class: '' },
        { path: '/store/kind', title: 'Natures',  icon: 'featured_play_list', class: '' },
        { path: '/store/stock', title: 'Stocks',  icon: 'all_inbox', class: '' },
    ] },
];

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {
  menuItems: any[];

  public establishments: Array<Establishment> = []
  public selectedEstablishment: Establishment = Establishment.default

  constructor(private _EstablishmentService: EstablishmentService, private _LoginService: LoginService, private _Router: Router, private _ToasterService: ToastrService) { }

  async ngOnInit() {
    try {  
      this.establishments = await this._EstablishmentService.getByCustomer(AppService.userAdmin.customer)
    } catch (error) {
      if (error.json().code === "0x1007") this._ToasterService.warning(`Vous n'êtes pas autorisé à effectuer cette action`)
      else this._ToasterService.error()
      console.error(error)
    }

    this.menuItems = ROUTES.filter(menuItem => menuItem);
  }
  isMobileMenu() {
      if ($(window).width() > 991) {
          return false;
      }
      return true;
  };

  public async handleSelectEstablishment(): Promise<void> {
    if (this.selectedEstablishment === Establishment.default) return
    try {
      await this._LoginService.auth(this.selectedEstablishment.accessKey, this.selectedEstablishment.version)
      AppService.establishment = this.selectedEstablishment
      await this._ToasterService.success("Connexion établie.")
      this._Router.navigateByUrl('/establishment/home')
      return Promise.resolve()
    } catch (error) {
      console.error("SelectComponent:handleSelectEstablishment", error)
      if (error.json() && error.json().code === "0x1007") this._ToasterService.warning(`Vous n'êtes pas autorisé à effectuer cette action`)
      else this._ToasterService.error("Ereur lors de la connexion.")
      return Promise.reject(error)
    }
  }
}
