import { Product } from "./Product";
import { ProductPrice } from "./ProductPrice";

export class Suggestion {
    // Common
    public id: number
    public name: string
    public code: string
    public designation: string
    public secondary: string
    public description: string
    public available: boolean
    public kind: SuggestionKind

    public parents?: Product[]
    public products?: Product[]
    public plu?: string
    public deliveryModes?: SuggestionDelivery[]
    public displayMethods?: SuggestionDisplay[]

    constructor (
        id?: number,
        name?: string, 
        code?: string, 
        designation?: string, 
        secondary?: string, 
        description?: string, 
        available?: boolean, 
        kind?: SuggestionKind, 
        plu?: string,
        products?: any[],
        parents?: any[],
        deliveryModes?: {deliveryMode: SuggestionDelivery}[],
        displayMethods?: {displayMethod: SuggestionDisplay}[]
    ) {
        this.id = id
        this.name = name
        this.code = code
        this.designation = designation
        this.secondary = secondary
        this.description = description
        this.available = available
        this.kind = kind
        this.plu = plu    

        this.deliveryModes = deliveryModes ? deliveryModes.map(dm => dm.deliveryMode) : undefined
        this.displayMethods = displayMethods ? displayMethods.map(dm => dm.displayMethod) : undefined

        this.products = products ? products.map(product => new Product(
            parseInt(product.product_id), 
            product.type, 
            product.name, 
            product.code,
            product.color,
            product.kind,
            product.picture,
            product.order ? parseInt(product.order) : undefined,
            (product.printer_bold === "TRUE") ? true : false,
            new ProductPrice(product.product_price.ratecard_product_id, product.product_price.price_novat),
            null,
            null,
            (product.type === "SET") ? parseInt(product.product_set_id) : null,
            (product.type === "EXTEND") ? parseInt(product.product_extend_id) : null,
            product.designation,
            product.description,
            product.printer_placement,
            null,
            null,
            null,
            (product.available === "TRUE") ? true : false,
            (product.favourite === "TRUE") ? true : false
        )).sort((a, b) => { return (a.order === b.order) ? 0 : (a.order < b.order) ? -1 : 1 }) : undefined


        this.parents = parents ? parents.map(product => new Product(
            parseInt(product.product_id), 
            product.type, 
            product.name, 
            product.code,
            product.color,
            product.kind,
            product.picture,
            product.order ? parseInt(product.order) : undefined,
            (product.printer_bold === "TRUE") ? true : false,
            new ProductPrice(product.product_price.ratecard_product_id, product.product_price.price_novat),
            null,
            null,
            (product.type === "SET") ? parseInt(product.product_set_id) : null,
            (product.type === "EXTEND") ? parseInt(product.product_extend_id) : null,
            product.designation,
            product.description,
            product.printer_placement,
            null,
            null,
            null,
            (product.available === "TRUE") ? true : false,
            (product.favourite === "TRUE") ? true : false
        )).sort((a, b) => { return (a.order === b.order) ? 0 : (a.order < b.order) ? -1 : 1 }) : undefined
    }
}

export enum SuggestionKind {
    DIALOG = 'DIALOG', //   dialog window,
    UPGRADE = 'UPGRADE', // upgrade of product in menu,
    EXTRA = 'EXTRA', // add extra product to menu,
    SUGGESTION = 'SUGGESTION', // suggestion in menu tunnel,
  }


export enum SuggestionDisplay {
    ONLY_ONCE = 'ONLY_ONCE', // show only once,
    BEFORE_PAYMENT = 'BEFORE_PAYMENT', // show before payment,
    PRODUCT_IN_MENU = 'PRODUCT_IN_MENU', // show on product in menu,
    NONE_SELECTED = 'NONE_SELECTED', // show if none of items from selection pattern was ordered
  }
  
  export enum SuggestionDelivery {
    EAT_IN = 'EAT_IN', // DINERING orderCode Symbioz
    TAKE_AWAY = 'TAKE_AWAY' // ORDERING orderCode Symbioz
  }